import LineChart from "../components/LineChart";
import React from "react";
import {
  faLightbulb,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Home() {
  return (
    <main className="flex-grow flex flex-col container mx-auto items-center justify-center text-center px-6 py-2 md:py-4">
      <h2 className="text-3xl md:text-4xl font-extrabold mb-4 text-gray-800">
        Welcome to Ethiopian Black Market Ex
      </h2>
      <p className="text-lg md:text-xg mb-2 text-gray-800">
        We provide real-time and historical data on the Ethiopian black market
        USD/ETB exchange rate.
        <br />
        Trusted and 100% reliable source
      </p>
      {/* alert price is getting spike */}
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
        role="alert"
      >
        <FontAwesomeIcon
          className="animate-ping"
          icon={faTriangleExclamation}
        />
        <strong className="font-bold pl-2">Alert!</strong>
        <span className="block sm:inline">
          {" "}
          The exchange rate is getting spike, please be cautious.
        </span>
      </div>
      {/* price prediction for the next days 
      1 USD = 157.79 ETB */}
      {/* <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4 flex items-center space-x-2 animate-bounce">
        <FontAwesomeIcon icon={faLightbulb} />
        <div>
          <strong className="font-bold">Prediction:</strong>
          <span className="block sm:inline animate-blink">
            {" "}
            The exchange rate will be <strong>158.79 ETB</strong> for 1 USD in
            the next days.
          </span>
        </div>
      </div> */}
      <div className="w-full flex justify-center mb-24 text-gray-800">
        <LineChart />
      </div>
      <div className="faq-section my-8">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">
          Frequently Asked Questions
        </h2>

        <div className="faq-item mb-4">
          <h3 className="text-lg font-semibold text-gray-700">
            1. What are black market exchange rates?
          </h3>
          <p className="text-gray-600">
            Black market exchange rates refer to unofficial trading rates for
            foreign currencies, often differing from official rates. These rates
            are common in countries with currency restrictions or high demand
            for foreign currencies.
          </p>
        </div>

        <div className="faq-item mb-4">
          <h3 className="text-lg font-semibold text-gray-700">
            2. Are the rates displayed accurate?
          </h3>
          <p className="text-gray-600">
            The rates on our platform are updated regularly to reflect the
            latest black market conditions. However, they are provided for
            informational purposes only and may vary in actual transactions.
          </p>
        </div>

        <div className="faq-item mb-4">
          <h3 className="text-lg font-semibold text-gray-700">
            3. How often are the exchange rates updated?
          </h3>
          <p className="text-gray-600">
            Exchange rates are updated hourly to ensure you get the most
            accurate and up-to-date information.
          </p>
        </div>

        <div className="faq-item mb-4">
          <h3 className="text-lg font-semibold text-gray-700">
            4. Can I use this website to trade currencies?
          </h3>
          <p className="text-gray-600">
            This website is for informational purposes only. Please consult
            authorized currency exchange providers for actual trading or
            transactions.
          </p>
        </div>

        <div className="faq-item mb-4">
          <h3 className="text-lg font-semibold text-gray-700">
            5. What is the source of the exchange rate data?
          </h3>
          <p className="text-gray-600">
            Our data is collected from reliable and trusted sources, including
            currency traders and market experts.
          </p>
        </div>
      </div>
      <p className="text-lg md:text-xl mb-2 font-semibold text-gray-700">
        Ethiopian black market rate in different countries around the world
      </p>
      <table className="min-w-full table-auto text-gray-800 bg-white rounded-lg shadow-md mt-4 mb-12">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border-b">Country</th>
            <th className="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+USD+in+United+States+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 USD in United States of America
              </a>
            </td>
            <td className="px-4 py-2">146.90 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+GBP+in+United+Kingdom+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 GBP in United Kingdom (England)
              </a>
            </td>
            <td className="px-4 py-2">171.35 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+EUR+in+Italy+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 EUR in Italy
              </a>
            </td>
            <td className="px-4 py-2">145.75 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+EUR+in+Germany+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 EUR in Germany
              </a>
            </td>
            <td className="px-4 py-2">145.10 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+EUR+in+Sweden+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 EUR in Sweden
              </a>
            </td>
            <td className="px-4 py-2">145.70 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+SEK+in+Saudi+Arabia+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 SEK in Saudi Arabia
              </a>
            </td>
            <td className="px-4 py-2">15.68 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+AED+in+UAE+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 AED in UAE
              </a>
            </td>
            <td className="px-4 py-2">35.83 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+ILS+in+China+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 ILS in China
              </a>
            </td>
            <td className="px-4 py-2">19.99 ETB</td>
          </tr>
        </tbody>
      </table>

      <p className="text-lg md:text-xl mb-2 font-semibold text-gray-700">
        Compare today's Euro to Ethiopian Birr (EUR to ETB) exchange rates with
        remittance service providers to see the difference in fees and exchange
        rates.
      </p>
      <table className="min-w-full table-auto text-gray-800 bg-white rounded-lg shadow-md mt-4 mb-12">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border-b">1 EUR</th>
            <th className="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.revolut.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Revolut (no fee)
              </a>
            </td>
            <td className="px-4 py-2">132.5948 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.dahabshiil.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Dahabshiil (no fee)
              </a>
            </td>
            <td className="px-4 py-2">145.0186 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.westernunion.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Western Union
              </a>
            </td>
            <td className="px-4 py-2">128.3601 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.talkremit.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Talke Remit
              </a>
            </td>
            <td className="px-4 py-2">145.0006 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.cambridgefx.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Cambridge Currencies
              </a>
            </td>
            <td className="px-4 py-2">129.9672 ETB</td>
          </tr>
        </tbody>
      </table>

      <p className="text-lg md:text-xl mb-2 font-semibold text-gray-700">
        Compare today's US Dollar to Ethiopian Birr (USD to ETB) exchange rates
        with remittance service providers to see the difference in fees and
        exchange rates.
      </p>
      <table className="min-w-full table-auto text-gray-800 bg-white rounded-lg shadow-md mt-4 mb-12">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border-b">1 USD</th>
            <th className="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.cashgo.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Cash Go (no fee)
              </a>
            </td>
            <td className="px-4 py-2">125.1201 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.westernunion.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Western Union
              </a>
            </td>
            <td className="px-4 py-2">123.4480 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.riamoneytransfer.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Ria Money Transfer
              </a>
            </td>
            <td className="px-4 py-2">118.5200 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.cambridgefx.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Cambridge Currencies
              </a>
            </td>
            <td className="px-4 py-2">123.7818 ETB</td>
          </tr>
        </tbody>
      </table>

      <p className="text-lg md:text-xl mb-2 font-semibold text-gray-700">
        Eritrean Nakfa exchange rate from different currencies
      </p>
      <table className="min-w-full table-auto text-gray-800 bg-white rounded-lg shadow-md mt-4 mb-12">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border-b">1 Nakfa</th>
            <th className="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+USD+in+Nakfa+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 USD
              </a>
            </td>
            <td className="px-4 py-2">15.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+GBP+in+Nakfa+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 GBP
              </a>
            </td>
            <td className="px-4 py-2">20.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+EUR+in+Nakfa+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 EUR
              </a>
            </td>
            <td className="px-4 py-2">17.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+SAR+in+Nakfa+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 SAR
              </a>
            </td>
            <td className="px-4 py-2">4.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+SEK+in+Nakfa+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 SEK
              </a>
            </td>
            <td className="px-4 py-2">1.50 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+AED+in+Nakfa+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 AED
              </a>
            </td>
            <td className="px-4 py-2">4.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.google.com/search?q=1+ILS+in+Nakfa+site:ethiopianblackmarket.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                1 ILS
              </a>
            </td>
            <td className="px-4 py-2">2.00 ERN</td>
          </tr>
        </tbody>
      </table>

      <p className="text-lg md:text-xl mb-2 font-semibold text-gray-700">
        Ethiopian Banks Stock Market, Price for 100 Stocks
      </p>
      <table className="min-w-full table-auto text-gray-800 bg-white rounded-lg shadow-md mt-4">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border-b">Bank</th>
            <th className="px-4 py-2 border-b">Price in ETB</th>
            <th className="px-4 py-2 border-b">Earning Per Share</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.zemenbank.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Zemen Bank
              </a>
            </td>
            <td className="px-4 py-2">135,000.00 ETB</td>
            <td className="px-4 py-2">35%</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.bankofabyssinia.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Bank Of Abyssinia
              </a>
            </td>
            <td className="px-4 py-2">134,800.00 ETB</td>
            <td className="px-4 py-2">30%</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.hibretbank.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Hibret Bank
              </a>
            </td>
            <td className="px-4 py-2">125,000.00 ETB</td>
            <td className="px-4 py-2">25%</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.coopbankoromia.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Cooperative Bank Of Oromia
              </a>
            </td>
            <td className="px-4 py-2">110,000.00 ETB</td>
            <td className="px-4 py-2">10%</td>
          </tr>
          <tr>
            <td className="px-4 py-2">
              <a
                href="https://www.nibbank.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Nib International Bank
              </a>
            </td>
            <td className="px-4 py-2">125,000.00 ETB</td>
            <td className="px-4 py-2">20%</td>
          </tr>
        </tbody>
      </table>
    </main>
  );
}

export default Home;
